import { BackendApi, WttRestResponseBody } from '../backend-api';
import {
  AccountabilityHpg,
  AccountabilityHpgsResponse,
  AccountabilityModel,
  AccountabilityResponse,
  AccountabilitySaveRequestModel,
  UnassginedGlobalDivisionsResponse,
  UnassginedGlobalDivisionsSuccessResponse,
  UserAssignmentUpdateResponse,
} from './accountablilty-response';
import { ApiResponse, isApiResponse } from '../api';

export class AccountabilityApi extends BackendApi {

  async getHpgs(unassigned: boolean): Promise<AccountabilityHpgsResponse> {
    const response = await this.mapGeneralErrors<AccountabilityHpgsResponse>(
      this.fetch('GET', '/api/v1/config/accountability/hpg', { queryParams: { unassigned: unassigned } }),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<AccountabilityHpg[]>;

    return {
      status: 'OK',
      hpgs: responseBody.data || [],
    };
  }

  async getAssignedHpgs(userId: number): Promise<AccountabilityHpgsResponse> {
    const response = await this.mapGeneralErrors<AccountabilityHpgsResponse>(
      this.fetch('GET', `/api/v1/config/accountability/user/${ userId }/hpg`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<AccountabilityHpg[]>;

    return {
      status: 'OK',
      hpgs: responseBody.data || [],
    };
  }

  async getAssignmentByHpgId(hpgId: number, userid: number): Promise<AccountabilityResponse> {
    const response = await this.mapGeneralErrors<AccountabilityResponse>(
      this.fetch('GET', `/api/v1/config/accountability/assignments/user/${ userid }/hpg/${ hpgId }`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<AccountabilityModel>;

    return {
      status: 'OK',
      accountabilityModel: responseBody.data,
    };
  }

  async getUnassignedGlobalDivisionsByHpgId(hpgId: number, userid: number): Promise<UnassginedGlobalDivisionsResponse> {
    const response = await this.mapGeneralErrors<UnassginedGlobalDivisionsSuccessResponse>(
      this.fetch('GET', `/api/v1/config/accountability/gd/user/${ userid }/${ hpgId }`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<UnassginedGlobalDivisionsSuccessResponse>;

    return {
      status: 'OK',
      hpg: responseBody.data.hpg,
      unassignedGlobalDivisions: responseBody.data.unassignedGlobalDivisions,
      canHaveEmptyList: responseBody.data.canHaveEmptyList,
    };
  }

  async saveAssignment(hpgId: number, userId: string, userAssignmentUpdate: AccountabilitySaveRequestModel): Promise<UserAssignmentUpdateResponse> {
    const response = await this.mapGeneralErrors<UserAssignmentUpdateResponse>(
      this.fetch('POST', `/api/v1/config/accountability/assignments/user/${ userId }/hpg/${ hpgId }`, {
        body: userAssignmentUpdate,
      }),
      async notOkResponse => {
        if (notOkResponse.status === 409) {
          const notOkResponseBody = await notOkResponse.json() as WttRestResponseBody<AccountabilityModel>;

          if (notOkResponseBody.responseCode === 10100) {
            return { status: 'ORPHAN_DETECTED' };
          }
        }
      },
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<AccountabilityModel>;
    return {
      status: 'OK',
      accountabilityModel: responseBody.data,

    };
  }

  async deleteAssignment(hpgId: number, userId: string): Promise<ApiResponse> {
    const response = await this.mapGeneralErrors<ApiResponse>(
      this.fetch('DELETE', `/api/v1/config/accountability/assignments/user/${ userId }/hpg/${ hpgId }`)
    );

    if (isApiResponse(response)) {
      return response;
    }

    return {
      status: 'OK'
    };
  }
}

export const accountabilityApi = new AccountabilityApi();
